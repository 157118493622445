
<script setup lang="ts">
    import { ref, watch } from "vue";
    import { OModal } from "o365-ui-components";

    export interface Props {
        participantRow?: any;
        roleId: number;
        idPath?: string;
    }

    const props = defineProps<Props>();
    const model = defineModel();

    const modal = ref<OModal>();

    const dsRoleMembers = $getOrCreateDataObject({
        id: "dsRoleMembers" + crypto.randomUUID(),
        viewName: "aviw_Complete_ActivitiesRoleMembers", // TODO: This view works but should be replaced
        maxRecords: 200,
        whereClause: "1=0",
        distinctRows: true,
        allowUpdate: false,
        allowInsert: false,
        allowDelete: false,
        fields: [{ "name": "Person", "sortOrder": 1, "sortDirection": "asc" }],
    });

    function load() {
        if (props.idPath && props.roleId) {
            dsRoleMembers.recordSource.whereClause = "'" + props.idPath + "' LIKE IdPath + '%' AND Role_ID = " + props.roleId;
        } else {
            dsRoleMembers.recordSource.whereClause = "1=0";
        }
        dsRoleMembers.load();
    }

    watch(model, (val) => {
        if (!modal.value) {
            return;
        }
        if (val) {
            modal.value.show();
            load();
        } else {
            modal.value.hide();
        }
    });
</script>

<template>
    <OModal ref="modal" @show="model = true" @hide="model = false">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("Role members") }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <template v-for="row in dsRoleMembers.data">
                        <div>
                            {{ row.Person }}
                        </div>
                    </template>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </OModal>
</template>